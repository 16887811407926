<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Klant verkoop</h1>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col-xl-6 col-xxl-3">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Van
                                </span>
                                <DatePicker :clearable="false" @input="dateChanged" v-model="from"  />
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-3">
                            <div class="datepicker-styling">
                                <span class="styled-label">
                                    Tot
                                </span>
                                <DatePicker :clearable="false" @input="dateChanged" v-model="till" />
                            </div>
                        </div>

                        <div class="col-6 col-xl-2 col-xxl-3">
                            <span class="styled-label">Per</span>
                            <select class="styled-input" @change="getData" v-model="showPer">
                                <option value="day">Dag</option>
                                <option value="week">Week</option>
                                <option value="month">Maand</option>
                            </select>
                        </div>

                        <div class="col-6 col-xl-2 col-xxl-3">
                            <span class="styled-label">
                                Toon
                            </span>
                            <div class="styled-checkbox d-inline-block">
                                <label>
                                    <input type="checkbox" disabled checked>
                                    <span>Producten zonder kilo's</span>
                                </label>
                            </div>
                        </div>

                        <div class="col-6 col-xl-4 col-xxl-3" style="position: relative">
                            <span class="styled-label">
                                Jaren
                            </span>
                            <select @change="dateChanged" v-model="years" class="styled-input">
                                <option :value="3">3 jaar</option>
                                <option :value="2">2 jaar</option>
                                <option :value="1">1 jaar</option>
                            </select>
                        </div>

                        <div class="col-xl-6 col-xxl-6">
                            <span class="styled-label">
                                Klanten
                            </span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="customerIds"
                                placeholder="Kies één of meerdere klanten"
                                :options="customers.sortBy('name').map(c => ({label: c.name, value: c.id})).all()"
                                @update:modelValue="customersChanged()"
                            />
                        </div>

                        <div class="col-6 col-xl-4 col-xxl-3" style="position: relative">
                            <span class="styled-label">
                                Artikelgroepen
                            </span>
                            <MultiSelect
                                :numberOfSelectedOptionsShown="2"
                                :selectAllOption="true"
                                v-model="typeIds"
                                placeholder="Kies één of meerdere artikelgroepen"
                                :options="types.map(t => ({label: t.translate('name'), value: t.id})).all()"
                                @update:modelValue="prevTypeIds = typeIds; getData()"
                            />
                            <a
                                @click="typeIds = prevTypeIds;getData()"
                                style="position:absolute; right: 20px; top:52px; background-color:white; border-radius:20px;"
                                v-if="prevTypeIds !== typeIds"
                            >
                                <Icon name="arrow-path" style="width:24px;" type="solid" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="loading">
        <loader />
    </div>
    <div v-else>
        <div class="graph-holder sold-graph-holder" v-if="customerIds.length > 0 && typeIds.length > 0">

            <div class="y-labels">
                <div v-for="n in 6" class="y-label" :style="`height: ${height/6}px`">
                    {{ formatNumber(Math.round(((maxValue/6) * (6-n+1)) / 10) * 10) }}
                </div>
                <div class="y-label">0</div>
            </div>

            <div class="graph">
                <div class="sold-graph" :style="`width: ${(periods.length*96)+3}px`">
                    <div :class="{today: period.isSame(now, showPer)}" class="column" v-for="period of periods">
                        <div class="bar-container">
                            <div v-for="barDetails of [
                                {class: 'super-prev-bar', subYears: -2},
                                {class: 'prev-bar', subYears: -1},
                                {class: 'current-bar', subYears: 0}
                            ]" :class="[barDetails.class]" class="bar" :style="`height: ${barHeight(period, barDetails.subYears)}%`">
                                <div
                                    v-for="radishData of bars.get(formatPeriod(period, barDetails.subYears))"
                                    :class="[getClassForRadish(radishData), 'inner-bar']"
                                    :style="`height: ${radishData.height}%`"
                                    v-wbtooltip:cursor="{
                                        text: tooltipText(radishData, period, barDetails, totalPeriodValues),
                                        class: (barHeight(period, barDetails.subYears) > 80 ? 'bottom' : '')
                                    }"
                                ></div>
                            </div>
                        </div>
                        <div class="label-holder">
                            <div class="label" v-html="getLabel(period)"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else class="no-data-placeholder">
            Geen gegevens gevonden met de huidige filters.
        </div>

        <div v-if="typeIds.length > 1" class="sub-graph-holder">
            <SubGraph
                v-for="typeModel of types.filter(t => typeIds.includes(t.id))"
                :type="typeModel" :data="data.map(di => di.first(type => (
                    type.type_id == typeModel.id
                )))"
                :periods="periods"
                :getLabel="getLabel" :getClassForRadish="getClassForRadish"
                :formattedPeriods="formattedPeriods"
                @select="typeIds = [typeModel.id]; getData()"
            />
        </div>

        <div v-else-if="dataPerArticle.keys().count() > 0 && typeIds.length > 0" class="sub-graph-holder">
            <ArticleGraph
                v-for="articleName of dataPerArticle.keys()"
                :name="articleName"
                :data="dataPerArticle.get(articleName)"
                :periods="periods"
                :getLabel="getLabel"
                :barClass="getClassForRadish({type_id: typeIds[0]})"
                :formattedPeriods="formattedPeriods"
                :years="years"
                @select="openModal = articleName"
            />
        </div>
    </div>

    <Modal exclude-save-button="true" :extra-large="true" close-classes="alert"  @close="openModal = false" v-if="openModal">
        <template v-slot:header>&nbsp;</template>
        <ArticleGraph
            :name="openModal" :data="dataPerArticle.get(openModal)"
            :periods="periods"
            :barClass="getClassForRadish({type_id: typeIds[0]})"
            :getLabel="getLabel" :getClassForRadish="getClassForRadish"
            :formattedPeriods="formattedPeriods"
            :years="years"
        />
    </Modal>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import OrderService from '@/services/http/order-service';
    import TypeService from '@/services/http/type-service';
    import moment from 'moment';
    import collect from 'collect.js'
    import useCustomerInsightsFunctionalities from './useCustomerInsightsFunctionalities';
    import SubGraph from './Sold/SubGraph.vue';
    import ArticleGraph from './Sold/ArticleGraph.vue';
    import CustomerService from '@/services/http/customer-service';
    import Type from '@/models/type.js';
    import Loader from '@/components/Loader.vue';

    const emit = defineEmits(['breadcrumbs']);

    emit('breadcrumbs', [
        {label: 'Insights', to: '/customer-insights'},
        {label: 'Klant verkoop'}
    ]);

    const columnWidth = '64px';
    const height = '100%';
    const now = moment();
    const years = ref(3);

    const {
        // Variables
        from, till,
        customerIds, customers,
        typeIds, types,

        // Computed
        weeks,

        // Functions
        formatNumber, getMaxValueRounded
    } = useCustomerInsightsFunctionalities();

    const data = ref(collect());
    const prevTypeIds = ref([]);
    const showPer = ref('week');
    const today = moment();

    const openModal = ref(false);

    const dataPerArticle = ref(collect());

    const loading = ref(false);

    const getData = function() {
        loading.value = true;
        if (customerIds.value.length === 0 || typeIds.value.length === 0) {
            data.value = collect();
            return;
        }

        const filterData = {
            show_per: showPer.value,
            from: from.value,
            till: till.value,
            customer_ids: customerIds.value,
            years: years.value
        }

        const promises = [];
        if (typeIds.value.length === 1) {
            promises.push(OrderService.getCustomerInsightsSoldPerArticle({
                ...filterData,
                type_id: typeIds.value[0],
            }).then(dataItems => {
                dataPerArticle.value = collect(dataItems).map(di => collect(di));
            }));
        }

        promises.push(OrderService.getCustomerInsightsSold({
            ...filterData,
            type_ids: typeIds.value,
            years: years.value
        }).then(dataItems => {
            data.value = collect(dataItems).map(di => {
                return collect(di).sortByDesc('type').sortBy('sequence');
            });
        }));

        return Promise.all(promises).then(() => {
            loading.value = false;
        });
    }

    const bars = computed(() => {
        const bars = {};

        for (const period of periods.value) {
            const superPrevKey = formatPeriod(period, -2);
            const prevKey = formatPeriod(period, -1);
            const curKey = formatPeriod(period);


            for (const key of [superPrevKey, prevKey, curKey]) {
                const other = {height: 0, amount: 0};
                const bar = {};
                const keyData = data.value.get(key) ?? [];

                for (const radishData of keyData) {
                    var amount = (radishData.amount / totalPeriodValues.value.get(key));
                    amount = (isNaN(amount) ? 0 : amount);

                    if (amount < 0.01) {
                        other.height += amount*100;
                        other.amount += parseInt(radishData.amount);
                    } else {
                        bar[radishData.type_id] = {
                            ...radishData,
                            height: amount*100
                        }
                    }
                }
                bar['other'] = other
                bars[key] = bar;
            }

        }

        return collect(bars);
    });

    const totalPeriodValues = computed(() => {
        return data.value.map(d => collect(d).sum('amount'));
    });

    const maxValue = computed(() => {
        if (data.value.count() <= 0) {
            return 0;
        }
        return getMaxValueRounded(totalPeriodValues.value.values().max())
    })

    const periods = computed(() => {
        let periods = [];

        const fromMoment = moment(from.value, 'YYYY-MM-DD').clone();
        const tillMoment = moment(till.value, 'YYYY-MM-DD');

        while (fromMoment.isSameOrBefore(tillMoment)) {
            periods.push(fromMoment.clone());
            fromMoment.add(1, showPer.value);
        }



        return periods;
    });

    const formattedPeriods = computed(() => {
        return periods.value.map(p => ({
            cur: formatPeriod(p),
            prev: formatPeriod(p, -1),
            superPrev: formatPeriod(p, -2),
            period: p
        }))
    })

    const barHeight = function(period, addYears = 0) {
        return (totalPeriodValues.value.get(formatPeriod(period, addYears))/maxValue.value) * 100;
    }

    const formatPeriod = function(period, addYears = 0) {
        if (showPer.value === 'day') {
            return (period.isoWeekYear() + addYears) + '' + String(period.isoWeek()).padStart(2, '0') + (period.isoWeekday()-1);
        }

        if (showPer.value === 'week') {
            return (period.isoWeekYear() + addYears) + '' + String(period.isoWeek()).padStart(2, '0');
        }

        return period.clone().add(addYears, 'year').format('YYYY-MM');
    }

    const getClassForRadish = function(data) {
        if (! data) return '';

        if (! ('type_id' in data)){
            return 'other';
        }

        return `type-${data.type_id}-non-minis`;
    }

    const getLabel = function(period) {
        if (showPer.value === 'day') {
            return (period.dayOfYear() === 1 ? period.format('YYYY') + ' ' : '')
                + (period.weekday() === 0 ? ('Week ' + period.isoWeek() + ' ')  : '' )
                + period.format('dddd')
        }

        if (showPer.value === 'week') {
            return (period.isoWeekYear() === today.year() ? '' : period.isoWeekYear() + ' ') + 'w' +period.isoWeek();
        }

        return period.format('MMMM YYYY');
    }

    const getTypeName = function(typeId) {
        const type = types.value.first(t => t.id == typeId);

        if (! type) {
            return 'Naamloos';
        }

        return type.translate('name')
    }

    const dateChanged = function() {
        customers.value = collect();

        getCustomers().then(() => {
            customerIds.value = customerIds.value.filter(
                customerId => customers.value.pluck('id').all().includes(customerId)
            );
            customersChanged()
        });
    }

    const customersChanged = function() {
        types.value = collect();
        getTypes().then(() => {
            typeIds.value = typeIds.value.filter(
                typeId => types.value.pluck('id').all().includes(typeId)
            );
            getData()
        });
    }

    const getCustomers = function() {
        return CustomerService.get({filters: {
            in_orders_from: from.value + ' 00:00:00',
            in_orders_till: till.value + ' 23:59:59'
        }}).then(customerModels => {
            customers.value = customerModels;
        });
    }

    const getTypes = function() {
        //console.log(customerIds.value);
        return TypeService.get({filters: {
            "articles.orderLines.order": {
                from: from.value,
                till: till.value,
                customer: {id_is_in: customerIds.value}
            },
            "articles": {
                size_id_is_not: 5,
                content_id_is_not: 28
            }
        }}).then(typeModels => {
            console.log(typeModels);
            types.value = typeModels.sortBy('sequence');
            types.value.push(new Type({
                id: 'mini',
                name: 'Mini\'s',
                sequence: types.value.max('sequence')+1
            }));
            //typeIds.value = types.value.pluck('id').all();
        });
    }

    const tooltipText = function(radishData, period, barDetails, totalPeriodValues) {
        const type = ('type_id' in radishData)
            ? getTypeName(radishData.type_id) + ' ' + (radishData.type === 'minis' ? ' Mini' : '')
            : 'Rest';

        const amount = formatNumber(radishData.amount);
        const totalAmount = formatNumber(totalPeriodValues.get(formatPeriod(period, barDetails.subYears)))

        let tooltip = `${type}: ${amount}<br/>Totaal: ${totalAmount}`;
        if (showPer.value === 'week') {
            const week = period.clone().month(6).subtract(barDetails.subYears, 'year').isoWeek(period.isoWeek());

            tooltip += `<br/>${week.startOf('week').format('DD MMM')} t/m ${week.endOf('week').format('DD MMM')}`
        }

        return tooltip;
    }

    Promise.all([
        getCustomers(),
        getTypes()
    ]).then(() => {
        customerIds.value = customers.value.pluck('id').all();
        typeIds.value = types.value.pluck('id').all();
        prevTypeIds.value = typeIds.value;
        getData()
    })
</script>
