<template>

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Dashboard</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">

            <div class="col-4">

                <ActiveHarvestEmployees />

                <div class="widget-holder">
                    Zaai kalender
                </div>
            </div>


            <div class="col-4">
                <div class="widget-holder">
                    <h5>Voorraad</h5>
                </div>

                <div class="widget-holder">
                    <h5>Orders</h5>
                </div>

                <div class="widget-holder">
                    <h5>Meldingen</h5>
                </div>
            </div>


            <div class="col-4">
                <div class="widget-holder">
                    <h5>Verkoop</h5>
                </div>
            </div>

        </div>
    </div>
</template>


<script setup>
    import { computed, onBeforeUnmount, ref } from 'vue';

    /*
    |--------------------------------------------------------------------------
    | Child components
    |--------------------------------------------------------------------------
    */
    import ActiveHarvestEmployees from './ActiveHarvestEmployees.vue';


    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "@/stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);



</script>
