<template>
    <component  :is="tag" :noFade="noFade" :large="true" id="create" @save="onSubmit()" @close="emit('close')">

        <template v-slot:header>
            <span>{{ header }}</span>
        </template>

        <span v-if="ready">
            <div class="edit-article">
                <div class="progress-bar">
                    <div v-if="dateSettings.active" class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('date')" :class="{active: (components[currentStep] === 'date'), filled: form.date}">Datum</div>
                        <div class="article-selection-result"><span v-if="form.date">{{ customFormatter(form.date, false) }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('chest')" :class="{active: (components[currentStep] === 'chest'), filled: form.chest_id}">Fust</div>
                        <div class="article-selection-result"><span v-if="form.chest_id">{{ ccst.chests.filter(chest => {return chest.id == form.chest_id;})[0].name }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('type')" :class="{active: (components[currentStep] === 'type'), filled: form.type_id}">Type</div>
                        <div class="article-selection-result"><span v-if="form.type_id">{{ ccst.types.filter(type => {return type.id == form.type_id;})[0].translate('name') }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('size')" :class="{active: (components[currentStep] === 'size'), filled: form.size_id}">Sortering</div>
                        <div class="article-selection-result"><span v-if="form.size_id">{{ ccst.sizes.filter(size => {return size.id === form.size_id})[0].translate('name') }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('content')" :class="{active: (components[currentStep] === 'content'), filled: form.content_id}">Inhoud</div>
                        <div class="article-selection-result"><span v-if="form.content_id">{{ ccst.contents.filter(content => {return content.id === form.content_id})[0].name }}</span></div>
                    </div>
                    <div v-if="locations !== undefined" class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('location')" :class="{active: (components[currentStep] === 'location'), filled: form.location_id}">Locatie</div>
                        <div class="article-selection-result"><span v-if="form.location_id">{{ locations.filter(location => {return location.id === form.location_id})[0].name }}</span></div>
                    </div>
                    <div class="progress-bar-step">
                        <div class="article-selection-label" @click="setStep('amount')" :class="{active: (components[currentStep] === 'amount'), filled: form[amountAs] > 0}">Aantal</div>
                        <div class="article-selection-result"><span v-if="form[amountAs]">{{ form[amountAs] }}</span></div>
                    </div>
                </div>
                <form @submit.prevent enctype="multipart/form-data">

                    <div class="modal-date-input" v-if="dateSettings.active" v-show="components[currentStep] === 'date'">
                        <VueDatePicker
                            inline
                            auto-apply
                            v-model="form.date"
                            :enable-time-picker="false"
                        ></VueDatePicker>
                    </div>

                    <ChooseProductOption
                        v-show="components[currentStep] === 'chest'"
                        :options="ccst.chests"
                        v-model="form.chest_id"
                        @chosen="currentStep++"
                        name="chest"
                        :value="form.chest_id"
                    />

                    <ChooseProductOption
                        v-show="components[currentStep] === 'type'"
                        :options="ccst.types"
                        v-model="form.type_id"
                        @chosen="currentStep++"
                        name="type"
                    />

                    <ChooseProductOption
                        v-show="components[currentStep] === 'size'"
                        :options="filteredSizes"
                        v-model="form.size_id"
                        @chosen="currentStep++"
                        name="size"
                    />

                    <ChooseProductOption
                        v-show="components[currentStep] === 'content'"
                        :options="ccst.contents"
                        v-model="form.content_id"
                        @chosen="currentStep++"
                        name="content"
                    />

                    <ChooseProductOption
                        v-if="locations !== undefined"
                        v-show="components[currentStep] === 'location'"
                        :options="locations"
                        v-model="form.location_id"
                        @chosen="currentStep++"
                        name="location"
                    />

                    <div v-show="components[currentStep] === 'amount'" class="create-step">
                        <div class="harvest-assignment-amount">
                            <numpad-input v-model="form[amountAs]"></numpad-input>
                        </div>
                    </div>
                </form>
            </div>
        </span>

        <template v-if="includeDelete" v-slot:before-buttons>
            <button class="modal-button alert" @click="emit('delete')" type="button">Verwijderen</button>
        </template>
    </component>
</template>

<script setup>
    import moment from 'moment';
    import Form from '../../helpers/form.js';
    import numpadInput from '../numpad-input.vue';
    import ChooseProductOption from './ChooseProductOption.vue';
    import { useCcstStore } from '../../stores/ccst';
    import { shallowRef, ref, computed } from 'vue';
    import SideModal from '../../pages/HarvestAssignment/SideModal.vue'


    const props = defineProps({
        'sideModal': Boolean,

        'header': String,

        'product': Object,

        'locations': Array,
        'locationId': [String, Number],
        'date': [String, Object],
        'start': [String, Number],
        'amount': [String, Number],

        'includeDelete': {type: Boolean, default: false},

        'step': [String, Number],

        'amountAs': {
            type: String,
            default: 'amount'
        },

        'noFade': {
            type: Boolean,
            default: false
        }
    });
    const emit = defineEmits(['submitted', 'close', 'delete']);

    const chests = computed(() => useCcstStore().chests.filter(c => c.name !== 'Bulk'));
    const contents = computed(() => useCcstStore().contents.filter(c => c.name !== 'Kilo'));
    const sizes = computed(() => useCcstStore().sizes);
    const types = computed(() => useCcstStore().types);

    const tag = ref(((props.sideModal === undefined || ! props.sideModal) ? 'Modal' : shallowRef(SideModal)));
    const ready = ref(false);
    const dateSettings = ref({
        active: false,
        value: moment(),
        disabledDates: {},
        inline: true
    });

    const ccst = ref({chests: [], contents: [], sizes: [], types: []});
    const components = ref(['date', 'chest', 'type', 'size', 'content', 'location', 'amount']);
    const currentStep = ref(props.step !== undefined ? props.step : 1)


    const getCcstFromProduct = function (camelCaseAttribute){
        const snakeCaseAttribute = camelCaseAttribute.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

        return props.product[(camelCaseAttribute in props.product ? camelCaseAttribute : snakeCaseAttribute)]
    }

    const form = ref({
        date: new Date(),
        location_id: (props.product !== undefined && props.product.locationId ? props.product.locationId : (props.locationId ? parseInt(props.locationId) : null)),
        [props.amountAs]: props.amount !== undefined ? props.amount : '',
        content_id: (props.product !== undefined ? getCcstFromProduct('contentId') : null),
        size_id: (props.product !== undefined ? getCcstFromProduct('sizeId') : null),
        type_id: (props.product !== undefined ? getCcstFromProduct('typeId') : null),
        chest_id: (props.product !== undefined ? getCcstFromProduct('chestId') : null),
        id: props.product ? props.product.id : null
    });

    const setDateSettings = function() {
        dateSettings.value.active = true;

        if (props.date === true) {
            return;
        }

        if (props.date.disabledDates) {
            dateSettings.value.disabledDates = props.date.disabledDates;
        }

        if (props.date.inline) {
            dateSettings.value.inline = props.date.inline;
        }

        if (props.date.date) {
            if (props.date.date instanceof moment) {
                form.value.date = props.date.date.toDate();
            } else {
                form.value.date = props.date.date;
            }
        } else {
            form.value.date = moment();
        }
    };

    const customFormatter = function(date, withWeek = true) {
        return moment(date).format('DD-MM-YYYY') + (withWeek ? ' week ' + moment(date).format('W') : '');
    }

    const setStep = function(name) {
        currentStep.value = components.value.findIndex(c => c === name);
    }

    const onSubmit = async function() {
        emit('submitted', new Form(form.value));
        emit('close');
        document.querySelector('body').classList = '';
    }

    if (props.date !== undefined) {
        setDateSettings();
    }

    if (props.locations === undefined) {
        components.value = ['chest', 'type', 'size', 'content', 'amount'];
    }

    if (props.date !== undefined) {
        components.value = ['date', ...components.value];
        if (currentStep.value === 0) {
            currentStep.value++;
        }
    }

    if (props.start !== undefined) {
        setStep(props.start)
    }

    ccst.value['chests'] = chests.value;
    ccst.value['contents'] = contents.value;
    ccst.value['sizes'] = sizes.value;
    ccst.value['types'] = types.value;
    ready.value = true;

    const filteredSizes = computed(() => {
        return sizes.value.filter(size => form.chest_id == 28 ? [4, 2].includes(size.id) : true)
    })
</script>
