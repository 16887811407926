import {createRouter, createWebHistory} from 'vue-router';
import authGuard from './guards/authGuard';
import guestGuard from './guards/guestGuard';

const routes = [];

// Login
import Login from './pages/Login.vue';
routes.push({
    path: '/',
    component: Login,
    name: 'login',
    beforeEnter: [guestGuard],
    meta: { middleware: 'guest' }
});

import ForgotPassword from './pages/ForgotPassword.vue';
routes.push({
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    beforeEnter: [guestGuard],
    meta: { middleware: 'guest' }
})

import RenewPassword from './pages/RenewPassword.vue';
routes.push({
    path: '/renew-password',
    component: RenewPassword,
    name: 'renew-password',
    meta: { requiredUserAction: true }
})

import ResetPassword from './pages/ResetPassword.vue';
routes.push({
    path: '/reset-password/:token/:email',
    component: ResetPassword,
    name: 'reset-password',
    props: true,
    beforeEnter: [guestGuard],
    meta: { middleware: 'guest' }
})

// Dashboard
import Dashboard from './pages/dashboard/Index.vue';
routes.push({
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
});
import NewDashboard from './pages/dashboard/New/Index.vue';
routes.push({
    path: '/new/dashboard',
    component: NewDashboard,
    name: 'new-dashboard',
});

// No permissions
import NoPermissions from './pages/Other/NoPermissions.vue';
routes.push({
    path: '/no-permissions',
    component: NoPermissions,
    name: 'no-permissions'
});

// No agreements
import AcceptAgreement from './pages/Other/AcceptAgreement.vue';
routes.push({
    path: '/accept-agreement',
    component: AcceptAgreement,
    name: 'accept-agreement',
    meta: { requiredUserAction: true }
});


import compareRoutes from './routes/compare-routes';
import harvestAssignmentRoutes from './routes/harvest-assignment-routes';
import harvestReportRoutes from './routes/harvest-report-routes';
import harvestScheduleRoutes from './routes/harvest-schedule-routes';
import notificationRoutes from './routes/notification-routes';
import orderRoutes from './routes/order-routes';
import performanceRoutes from './routes/performance-routes';
import registrationRoutes from './routes/registration-routes';
import sowingPlanningRoutes from './routes/sowing-planning-routes';
import settingRoutes from './routes/setting-routes';
import stockRoutes from './routes/stock-routes';
import userRoutes from './routes/user-routes';
import workListRoutes from './routes/work-list-routes';
import purchaseRoutes from './routes/purchase-routes';
import productionControlRoutes from './routes/production-control-routes';
import productionForecastRoutes from './routes/production-forecast-routes';
import dayHarvestRoutes from './routes/day-harvest-routes';
import customerInsightsRoutes from './routes/customer-insights-routes';

routes.push(
    ...compareRoutes,
    ...dayHarvestRoutes,
    ...harvestAssignmentRoutes,
    ...harvestReportRoutes,
    ...harvestScheduleRoutes,
    ...notificationRoutes,
    ...orderRoutes,
    ...performanceRoutes,
    ...registrationRoutes,
    ...sowingPlanningRoutes,
    ...settingRoutes,
    ...stockRoutes,
    ...userRoutes,
    ...workListRoutes,
    ...purchaseRoutes,
    ...productionControlRoutes,
    ...productionForecastRoutes,
    ...customerInsightsRoutes
);

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are usi\ng the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach(authGuard);




export default router;
