import moment from 'moment';
import { collect } from "collect.js";
import settingService from '../../services/http/setting-service';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default function useSaleForecastCalculations() {

    const query = useRoute().query;

    const f = ref('from' in query ? query.from : moment().subtract(1, 'month').startOf('isoweek').format('YYYY-MM-DD'));
    const t = ref('till' in query ? query.till : moment().add(3, 'month').endOf('isoweek').format('YYYY-MM-DD'));

    const from = ref(null);
    const till = ref(null);

    const lastYearFrom = ref(null);
    const lastYearTill = ref(null);

    const twoYearFrom = ref(null);
    const twoYearTill = ref(null);

    const threeYearFrom = ref(null);
    const threeYearTill = ref(null);

    const trendFrom = ref(null);
    const trendTill = ref(null);

    const lastYearTrendFrom = ref(null);
    const lastYearTrendTill = ref(null);

    const today = ref(moment());

    const weeklyTrend = ref(0);

    const weeks = computed(() => {
        let weeks = [];

        if (from.value) {
            for (let weekNr = 0; weekNr <= till.value.diff(from.value, 'week'); weekNr++) {
                let week = {
                    current: from.value.clone().add(weekNr, 'week'),
                    prev: lastYearFrom.value.clone().add(weekNr, 'week'),
                    prevTwo: twoYearFrom.value.clone().add(weekNr, 'week'),
                    prevThree: threeYearFrom.value.clone().add(weekNr, 'week')
                };
                weeks.push(week);
            }
        }

        return weeks;
    });

    const betweens = computed(() => {
        return [{
            from: from.value.format('YYYY-MM-DD HH:mm:ss'),
            till: till.value.format('YYYY-MM-DD HH:mm:ss')
        }, {
            from: lastYearFrom.value.format('YYYY-MM-DD HH:mm:ss'),
            till: lastYearTill.value.format('YYYY-MM-DD HH:mm:ss')
        }, {
            from: twoYearFrom.value.format('YYYY-MM-DD HH:mm:ss'),
            till: twoYearTill.value.format('YYYY-MM-DD HH:mm:ss')
        }, {
            from: threeYearFrom.value.format('YYYY-MM-DD HH:mm:ss'),
            till: threeYearTill.value.format('YYYY-MM-DD HH:mm:ss')
        }];
    });

    const setDates = function(fromDate = null, tillDate = null)
    {
        if (! fromDate) {
            from.value = moment(f.value).startOf('isoWeek');
        }

        if (! tillDate) {
            till.value = moment(t.value).endOf('isoWeek');
        }

        lastYearFrom.value = moment(`06-${from.value.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(from.value.isoWeek()).startOf('isoWeek');
        lastYearTill.value = moment(`06-${till.value.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(till.value.isoWeek()).endOf('isoWeek');

        twoYearFrom.value = moment(`06-${from.value.isoWeekYear()-2}`, 'MM-YYYY').isoWeek(from.value.isoWeek()).startOf('isoWeek');
        twoYearTill.value = moment(`06-${till.value.isoWeekYear()-2}`, 'MM-YYYY').isoWeek(till.value.isoWeek()).endOf('isoWeek');

        threeYearFrom.value = moment(`06-${from.value.isoWeekYear()-3}`, 'MM-YYYY').isoWeek(from.value.isoWeek()).startOf('isoWeek');
        threeYearTill.value = moment(`06-${till.value.isoWeekYear()-3}`, 'MM-YYYY').isoWeek(till.value.isoWeek()).endOf('isoWeek');

        if (from.value.isAfter(today.value)) {
            trendFrom.value = moment().subtract(weeklyTrend.value, 'week').startOf('isoWeek');
            trendTill.value = moment().subtract(1, 'week').endOf('isoWeek');
        } else {
            trendFrom.value = from.value.clone().subtract(weeklyTrend.value, 'week').startOf('isoWeek');
            trendTill.value = from.value.clone().subtract(1, 'week').endOf('isoWeek');
        }

        lastYearTrendFrom.value = moment(`06-${trendFrom.value.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(trendFrom.value.isoWeek()).startOf('isoWeek');
        lastYearTrendTill.value = moment(`06-${trendTill.value.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(trendTill.value.isoWeek()).endOf('isoWeek');
    }

    const customFormatter = function(date) {
        date = moment(date);

        if (date.isoWeekYear() !== moment().isoWeekYear()) {
            return date.isoWeekYear() + ' - Week ' + date.isoWeek();
        }

        return 'Week ' + date.isoWeek();
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Trend & Gains
    // ---------------------------------------------------------------------------------------------------------------------

    const calculateTrend = function(gains) {
        let gainsThatCount = gains.filter(g => g !== null);

        if (gainsThatCount.length === 0) {
            return 1;
        }

        return 1+((gainsThatCount.reduce((a,b) => (a+b)) / gainsThatCount.length) / 100);
    }

    const calculateGainsComparedToLastYear = function(bunches, lastYearBunches) {
        if (bunches === 0 || lastYearBunches === 0) {
            return null;
        }

        return (-((1- (bunches/lastYearBunches))*100));
    }

    const getTrent = function() {
        return settingService.first({filters: {
            key_is: 'weekly_trend'
        }}).then(setting => {
            weeklyTrend.value = (setting === undefined ? 0 : parseInt(setting.value));
        });
    }

    return {

        // Variables
        f, t, from, till, lastYearFrom, lastYearTill, twoYearFrom, twoYearTill,
        threeYearFrom, threeYearTill, trendFrom, trendTill, lastYearTrendFrom,
        lastYearTrendTill, today, weeklyTrend,

        // Computed
        weeks, betweens,

        // Functions
        setDates, customFormatter, calculateTrend, calculateGainsComparedToLastYear, getTrent
    }
}
